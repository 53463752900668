<template>
  <div class="page-cu-container">
    <div class="page-cu-top">
      <a-form layout="inline">
        <a-form-item>
          <a-button type="primary" @click="addAction" icon="plus-circle"
            >添加时间段</a-button
          >
        </a-form-item>
      </a-form>
    </div>
    <div class="page-cu-main">
      <a-table
        :columns="columns"
        :data-source="list"
        rowKey="id"
        bordered
        :pagination="false"
      >
        <span slot="action" slot-scope="text, record">
          <a @click="editAction(record)"><a-icon type="edit" /> 编辑</a>
          <a-divider type="vertical" />
          <a @click="delConfirm(record.id)" class="text-red"
            ><a-icon type="close" />删除</a
          >
        </span>
      </a-table>
    </div>
    <div class="page-cu-pagination">
      <a-pagination
        :page-size.sync="page.pageSize"
        :total="page.totalRow"
        v-model="page.start"
        @change="changePage"
      />
    </div>
    <div class="page-cu-pagination"></div>
    <a-modal
      title="打卡时间段设置"
      :visible="actionVisible"
      @ok="actionData"
      ok-text="确认"
      cancel-text="取消"
      @cancel="cancel"
      :confirmLoading="$store.state.requestLoading"
    >
      <a-form-model
        ref="formModel"
        :rules="formModelRules"
        :model="formModel"
        layout="vertical"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 18 }"
      >
        <a-form-model-item label="开始时间" prop="start_time">
          <a-time-picker value-format='HH:mm:ss' v-model="formModel.start_time" style="width: 100%" />
        </a-form-model-item>
        <a-form-model-item label="结束时间" prop="end_time">
          <a-time-picker value-format='HH:mm:ss' v-model="formModel.end_time" style="width: 100%" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import * as Api from "./api";
export default {
  name: "index",
  data() {
    return {
      formModel: {
        id: "",
        start_time: null,
        end_time: null,
      },
      formModelRules: {
        start_time: [
          { required: true, message: "请选择开始时间", trigger: "change" },
        ],
        end_time: [
          { required: true, message: "请选择结束时间", trigger: "change" },
        ],
      },
      columns: [
        {
          title: "开始时间",
          dataIndex: "start_time",
        },
        {
          title: "结束时间",
          dataIndex: "end_time",
        },
        {
          title: "操作",
          width: 200,
          scopedSlots: { customRender: "action" },
        },
      ],
      page: {
        start: 1,
        totalRow: 0,
        pageSize: 0,
        limit: 20,
      },
      list: [],
      actionVisible: false,
      record: "",
      menuList: [],
      showTree: false,
      expandedKeys: [],
      autoExpandParent: true,
      treeFields: {
        children: "children",
        title: "name",
        key: "id",
      },
      selectedIds: [],
      selectHalf: [],
      submitFlag: true,
      // 解决数据树父子节点选中的问题
      checkedKeys: { checked: [], halfChecked: [] },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    /**
     * 添加，更新
     *
     */
    actionData() {
      this.$refs.formModel.validate(async (valid) => {
        if (valid) {
          let res = null;
          if (this.formModel["id"]) {
            res = await Api.Update(this.formModel);
          } else {
            res = await Api.Save(this.formModel);
          }

          if (res && res["code"] == "0") {
            this.$message.success(res.message);
            this.actionVisible = false;
            this.getList();
          } else {
            this.$message.error(res.message);
          }
        }
      });
    },
    /*
     * 表单重置
     **/
    resetFormModel() {
      this.checkedKeys.checked = [];
      this.checkedKeys.halfChecked = [];
      for (let key in this.formModel) {
        this.formModel[key] = "";
      }
    },
    // 菜单选择
    handlerCheck(n, e) {
      this.checkedKeys = { checked: n, halfChecked: e.halfCheckedKeys };
    },
    // 打开添加角色窗
    addAction() {
      this.resetFormModel();
      this.actionVisible = true;
    },

    // 编辑角色
    editAction(record) {
      console.log(record);
      this.resetFormModel();
      this.formModel.id = record.id;
      this.formModel.start_time = record.start_time;
      this.formModel.end_time = record.end_time;
      this.actionVisible = true;
    },

    // 关闭窗口
    cancel() {
      this.actionVisible = false;
    },

    // 删除确认
    delConfirm(id) {
      let that = this;
      this.$confirm({
        title: "信息提示",
        okType: "danger",
        okText: "确定",
        cancelText: "取消",
        content: "您确定要删除吗?",
        onOk() {
          that.del(id);
        },
        onCancel() {},
      });
    },

    // 删除
    async del(id) {
      let res = await Api.Delete({ id: id });
      if (res && res.code == "0") {
        this.$message.success(res.message);
        this.getList();
      } else {
        this.$message.error(res.message);
      }
    },
    // 列表
    async getList() {
      let res = await Api.List({
        start: this.page.start,
        limit: this.page.limit,
      });
      this.page.totalRow = res.page.totalRow;
      this.page.pageSize = res.page.pageSize;
      this.list = res.page.list;
    },

    // 获取菜单列表
    async getMenuList() {
      let res = await Api.MenuList();
      this.menuList = res.treeList;
    },
    changePage(currentPage) {
      this.page.start = currentPage;
      this.getList();
    },
  },
};
</script>

<style scoped>
</style>
